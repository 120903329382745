<template>
  <div class="calendar-chart ">
    <div class="calendar-map"></div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import 'jquery-ui/ui/widgets/tooltip'

export default {
  name: 'testChart',
  props: {
    dataList: {
      type: Object,
      required: true,
    },
  },
  computed: {
    data() {
      return this.dataList.data.map(item => {
        return {
          Date: item.dt,
          Comparison_Type: item.components[this.dataList.index],
        }
      })
    },
  },
  watch: {
    data() {
      this.updateChart()
    },
  },
  mounted() {
    this.drawChart()
  },
  methods: {
    drawChart() {
      this.svg = this.createSvg()
      this.addWeekDays(this.svg)
      this.addMonths(this.svg)
      this.rect = this.createCalendarCells(this.svg)
      this.addMonthPaths(this.svg)
      this.updateChart()
    },
    createSvg() {
      var width = 900,
        height = 105,
        cellSize = 14
      let currentYear = new Date().getFullYear()
      var svg = d3
        .select('.calendar-map')
        .selectAll('svg')
        .data(d3.range(currentYear - 1, currentYear + 1))
        .enter()
        .append('svg')
        .attr('width', '100%')
        .attr('data-height', '0.5678')
        .attr('viewBox', '0 0 900 120')
        .attr('class', 'RdYlGn')
        .append('g')
        .attr('transform', 'translate(' + (width - cellSize * 50) / 2 + ',' + (height - cellSize * 7 + 15) + ')')

      svg
        .append('text')
        .attr('transform', 'translate(-60,' + cellSize * 3.5 + ')rotate(-90)')
        .style('text-anchor', 'middle')
        .style('fill', 'white')
        .text(function(d) {
          return d
        })

      return svg
    },
    addWeekDays(svg) {
      var cellSize = 14
      var week_days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      for (var i = 0; i < 7; i++) {
        svg
          .append('text')
          .attr('transform', 'translate(-30,' + (cellSize + 0.5) * (i + 0.6) + ')')

          .attr('fill', '#ffffff')
          .text(week_days[i])
          .style('font-size', '10px')
      }
    },
    addMonths(svg) {
      var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      var legend = svg
        .selectAll('.legend')
        .data(month)
        .enter()
        .append('g')
        .attr('class', 'legend')
        .attr('transform', function(d, i) {
          return 'translate(' + ((i + 1) * 60 + 1) + ',0)'
        })

      legend
        .append('text')
        .attr('class', function(d, i) {
          return month[i]
        })
        .attr('fill', '#ffffff')
        .style('text-anchor', 'end')
        .attr('dy', '-.25em')
        .text(function(d, i) {
          return month[i]
        })
    },
    createCalendarCells(svg) {
      var cellSize = 14
      var day = d3.timeFormat('%w')
      var week = d3.timeFormat('%U')
      var format = d3.timeFormat('%Y%m%d')

      return svg
        .selectAll('.day')
        .data(function(d) {
          return d3.timeDays(new Date(d, 0, 1), new Date(d + 1, 0, 1))
        })
        .enter()
        .append('rect')
        .attr('class', 'day')
        .attr('width', cellSize)
        .attr('height', cellSize)
        .attr('x', function(d) {
          return week(d) * cellSize
        })
        .attr('y', function(d) {
          return day(d) * cellSize
        })
        .attr('fill', 'rgba(128, 128, 128, 0.5)')
        .datum(format)
    },
    addMonthPaths(svg) {
      var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      svg
        .selectAll('.month')
        .data(function(d) {
          return d3.timeMonths(new Date(d, 0, 1), new Date(d + 1, 0, 1))
        })
        .enter()
        .append('path')
        .attr('class', 'month')
        .attr('id', function(d, i) {
          return month[i]
        })
        .attr('d', this.monthPath)
        .attr('stroke', '#000000')
        .attr('fill', 'none')
    },
    updateChart() {
      var color = function(value) {
        if (value >= 0 && value <= 50) {
          return '#009966'
        } else if (value > 50 && value <= 100) {
          return '#ffde33'
        } else if (value > 100 && value <= 150) {
          return '#ff9933'
        } else if (value > 150 && value <= 200) {
          return '#cc0033'
        } else if (value > 200 && value <= 300) {
          return '#660099'
        } else if (value > 300) {
          return '#7e0023'
        }
      }
      var csv = this.data
      csv.forEach(function(d) {
        d.Comparison_Type = parseInt(d.Comparison_Type)
      })

      var data = d3
        .nest()
        .key(function(d) {
          return d.Date.replace(/-/g, '')
        })
        .rollup(function(d) {
          return d[0].Comparison_Type
        })
        .map(csv)
      var tooltip = d3.select('.calendar-map').select('.tooltip')

      if (tooltip.empty()) {
        tooltip = d3
          .select('.calendar-map')
          .append('div')
          .attr('class', 'tooltip')
          .style('opacity', 0)
          .style('position', 'absolute')
          .style('z-index', '10')
          .style('bottom', '-20px')
          .style('left', '0px')
      }
      this.rect
        .filter(function(d) {
          return `$${d}` in data
        })
        .attr('fill', function(d) {
          return color(data[`$${d}`])
        })
        .attr('data-title', function(d) {
          return 'value : ' + Math.round(data[`$${d}`] + ', date : ' + d)
        })
        .on('mouseover', function(d) {
          let dateStr = d
          let year = dateStr.substring(0, 4)
          let month = dateStr.substring(4, 6)
          let day = dateStr.substring(6, 8)
          let date = new Date(dateStr.substring(0, 4), month - 1, day)
          let formattedDate = `${year}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
            date.getDate(),
          ).padStart(2, '0')}`
          tooltip
            .transition()
            .duration(200)
            .style('opacity', 0.9)
          tooltip.html('Value: ' + Math.round(data[`$${d}`]) + ', Date: ' + formattedDate)
        })
        .on('mouseout', function(d) {
          tooltip.style('opacity', 0)
        })
    },
    monthPath(t0) {
      var cellSize = 14
      var t1 = new Date(t0.getFullYear(), t0.getMonth() + 1, 0),
        d0 = +d3.timeFormat('%w')(t0),
        w0 = +d3.timeFormat('%U')(t0),
        d1 = +d3.timeFormat('%w')(t1),
        w1 = +d3.timeFormat('%U')(t1)
      return (
        'M' +
        (w0 + 1) * cellSize +
        ',' +
        d0 * cellSize +
        'H' +
        w0 * cellSize +
        'V' +
        7 * cellSize +
        'H' +
        w1 * cellSize +
        'V' +
        (d1 + 1) * cellSize +
        'H' +
        (w1 + 1) * cellSize +
        'V' +
        0 +
        'H' +
        (w0 + 1) * cellSize +
        'Z'
      )
    },
  },
}
</script>

<style scoped>
.calendar-map {
  width: 100%;
  height: 100%;
}
.calendar-chart {
  position: relative;
}
.custom-btn {
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
}
</style>
