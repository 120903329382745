var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height",staticStyle:{"width":"100%","height":"calc(100vh - 70px)","overflow":"hidden"},attrs:{"column":""}},[_c('v-layout',[_c('v-layout',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{ref:"dashboardContainer",attrs:{"cols":"4"}},[_c('v-card',{staticClass:"custom-card-bg-0",staticStyle:{"height":"calc(100vh - 80px)","overflow":"auto"}},[_c('Dashboard',{ref:"dashboard"})],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-layout',{staticClass:"pl-4",staticStyle:{"height":"100%"},attrs:{"column":""}},[_c('v-card',{staticStyle:{"width":"100%","height":"100%","position":"relative"},attrs:{"disabled":_vm.loading}},[_c('div',{ref:"mapContainer",staticStyle:{"width":"100%","height":"100%","position":"relative"}},[(!_vm.showLegend)?_c('v-btn',{staticStyle:{"position":"absolute","bottom":"12px","left":"12px","z-index":"2"},attrs:{"color":"bgContainer","fab":""},on:{"click":function($event){_vm.showLegend = true}}},[_c('v-icon',[_vm._v("mdi-map-legend")])],1):_vm._e(),(_vm.showLegend)?_c('v-layout',{staticClass:"px-2 py-1 mt-1 legend",staticStyle:{"height":"185px","font-size":"12px","overflow-y":"auto","background":"var(--v-bgListItem-base)","border-radius":"8px"},attrs:{"column":""}},[_c('v-layout',[_c('h4',[_vm._v("Legend")]),_c('v-spacer'),_vm._v(" "),_c('v-icon',{on:{"click":function($event){_vm.showLegend = false}}},[_vm._v("mdi-close")])],1),_vm._l((_vm.legend),function(item,i){return _c('v-layout',{key:i,staticStyle:{"max-height":"30px"},attrs:{"align-center":""}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-layout',_vm._g(_vm._b({staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},'v-layout',attrs,false),on),[_c('div',{staticClass:"mr-3",staticStyle:{"width":"25px","height":"25px"},style:({ 'background-color': item.color })}),_c('span',{staticStyle:{"font-size":"14px","width":"28px"}},[_vm._v(_vm._s(item.min))]),(item.max)?_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("-")]):_vm._e(),(!item.max)?_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("+")]):_vm._e(),_c('span',{staticClass:"ml-2 mr-2",staticStyle:{"font-size":"14px","width":"30px"}},[_vm._v(_vm._s(item.max))]),_c('span',{staticStyle:{"font-size":"14px","width":"fit-content"}},[_vm._v(_vm._s(item.name))]),_c('v-spacer'),_c('v-spacer')],1)]}}],null,true)},[_c('span',{staticStyle:{"white-space":"pre-wrap","display":"block","max-width":"200px"}},[_vm._v(_vm._s(item.description))])])],1)})],2):_vm._e(),_c('Map',{ref:"map",attrs:{"isLoading":_vm.loading},on:{"showHistorical":function (feature) { return _vm.getHistoricalData(feature); },"showDashBoard":function (feature, isUpdate) { return _vm.$refs.dashboard.showDashboard(feature, isUpdate); }}}),(_vm.elements)?_c('TourGuide',{ref:"tourGuide",staticStyle:{"position":"absolute","bottom":"40px","right":"125px","z-index":"2"},attrs:{"color":'#e3e3e3',"iconColor":'#66808d',"elements":_vm.elements}}):_vm._e()],1)]),_c('v-card',{staticClass:"custom-card-bg-0 mt-4  ",class:{ 'mb-5': !_vm.showable },style:({
                height: _vm.currentStation && _vm.showable ? 'fit-content' : '5%',
                'min-height': _vm.currentStation && _vm.showable ? 'fit-content' : '5%',
              }),attrs:{"width":"100%"}},[_c('div',{ref:"chart",staticClass:" ",staticStyle:{"flex":"none","width":"100%","min-height":"50px","border":"1px solid #2F3241","border-radius":"8px","position":"relative","overflow":"hidden"}},[_c('div',[_c('div',{staticStyle:{"flex":"none","width":"100%","height":"fit-content","min-height":"0"}},[_c('v-layout',{staticClass:"fill-height",attrs:{"align-center":""}},[_c('v-tabs',{staticStyle:{"max-height":"50px","border-top-left-radius":"8px","border-top-right-radius":"8px"},attrs:{"fixed-tabs":"","height":"50"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{staticClass:"ml-1 custom-tab-name",attrs:{"disabled":!_vm.currentStation}},[_vm._v(" Air Quality Index Historical ")]),_c('v-tab',{staticClass:"ml-0 custom-tab-name",attrs:{"disabled":!_vm.currentStation}},[_vm._v(" Daily air quality distribution ")]),_c('v-spacer'),_c('v-divider',{attrs:{"vertical":""}}),_c('v-btn',{staticClass:"mr-2 mt-2 ml-2",attrs:{"disabled":!_vm.currentStation,"icon":"","small":""},on:{"click":function($event){_vm.showable = !_vm.showable}}},[_c('v-icon',[_vm._v(_vm._s(_vm.showable ? 'icon-chevrons_down' : 'icon-chevrons_up'))])],1)],1)],1)],1)]),_c('v-overlay',{attrs:{"value":_vm.chartLoading,"absolute":""}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"64"}})],1),_c('v-divider'),_c('v-layout',{staticClass:"ml-2 mt-2"},[(_vm.currentStation && _vm.showable)?_vm._l((_vm.historical.data && _vm.historical.data[0] && _vm.historical.data[0].components
                        ? Object.keys(_vm.historical.data[0].components)
                        : []),function(i){return _c('div',{key:i},[_c('v-card',{staticClass:"custom-card-bg-0 custom-btn ",class:{ 'custom-card-bg-2': _vm.historical.index == i },staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.historical.index = i}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIndexName(i).name)}})])],1)}):_vm._e(),_c('v-spacer'),(_vm.tab == 1 && _vm.showable)?_c('h4',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.historical.data.length)+" days in totals")]):_vm._e()],2),_c('v-tabs-items',{staticClass:"custom-menu-tab",staticStyle:{"height":"calc(100% - 50px)","background-color":"transparent"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"tab1",staticStyle:{"height":"100%"}},[(_vm.currentStation && _vm.showable)?_c('div',{staticClass:"ml-2 mb-5",staticStyle:{"height":"100%"}},[_c('CalendarChart',{attrs:{"dataList":_vm.historical}})],1):_vm._e()]),_c('v-tab-item',{style:({ height: _vm.tabHeight })},[(_vm.currentStation && _vm.showable)?_c('distribution',{attrs:{"dataList":_vm.historical,"tabHeight":_vm.tabHeight}}):_vm._e()],1)],1)],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }