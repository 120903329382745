<template>
  <v-dialog v-model="createDialog" persistent width="500">
    <v-card class="custom-card-bg-gradient" height="100%" width="100%">
      <DialogHeader :is-close="true" :title="'Create layer'" @close="createDialog = false" />
      <v-card-text>
        <v-form ref="formData" v-model="valid" onSubmit="return false;">
          <v-text-field
            v-model="name"
            :rules="[rules.nameRequired]"
            autofocus
            dense
            label="Name"
            outlined
            placeholder="Name"
          ></v-text-field>
          <v-layout>
            <v-spacer />
            <v-btn color="primary" rounded @click="submitData">
              Submit
            </v-btn>
          </v-layout>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogHeader from '@/components/DialogHeader.vue'

export default {
  name: 'BasicCreateForm',
  components: { DialogHeader },
  data() {
    return {
      createDialog: false,
      valid: false,
      rules: {
        nameRequired: value => !!value || 'Name is required',
      },
      name: undefined,
    }
  },
  props: {},
  watch: {
    createDialog() {
      this.name = undefined
    },
  },
  methods: {
    openDialog() {
      this.createDialog = true
    },
    submitData() {
      this.$refs.formData.validate()
      if (this.valid) {
        this.$emit('submitSave', this.name)
        this.createDialog = false
      }
    },
  },
}
</script>

<style scoped></style>
