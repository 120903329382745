import api from '@/api/api'
import config from '@/config'

export async function getStations(param) {
  return await api.get(`${config.backend}/map/air-station`, param)
}
export async function getForecast(param) {
  return await api.get(`${config.backend}/map/forecast-air`, param)
}
export async function getHistorical(param) {
  return await api.get(`${config.backend}/map/historical-air`, param)
}

