<template>
  <div class="fill-height" column style="width: 100%; height: calc(100vh - 70px);overflow: hidden;">
    <v-layout>
      <v-layout>
        <v-row no-gutters>
          <v-col ref="dashboardContainer" cols="4">
            <v-card style="height: calc(100vh - 80px);overflow: auto;" class="custom-card-bg-0">
              <Dashboard ref="dashboard" />
            </v-card>
          </v-col>
          <v-col cols="8">
            <v-layout class="pl-4" column style="height: 100%;">
              <v-card :disabled="loading" style="width: 100%; height: 100%;position: relative;">
                <div style="width: 100%; height: 100%;position: relative;" ref="mapContainer">
                  <v-btn
                    v-if="!showLegend"
                    color="bgContainer"
                    fab
                    style="position: absolute; bottom: 12px; left: 12px; z-index: 2"
                    @click="showLegend = true"
                  >
                    <v-icon>mdi-map-legend</v-icon>
                  </v-btn>

                  <v-layout
                    class="px-2 py-1 mt-1 legend"
                    column
                    v-if="showLegend"
                    style="height: 185px; font-size: 12px; overflow-y: auto; background: var(--v-bgListItem-base); border-radius: 8px"
                  >
                    <v-layout
                      ><h4>Legend</h4>
                      <v-spacer></v-spacer> <v-icon @click="showLegend = false">mdi-close</v-icon></v-layout
                    >
                    <v-layout v-for="(item, i) in legend" :key="i" align-center style="max-height: 30px">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-layout
                            v-bind="attrs"
                            v-on="on"
                            style="display: flex;justify-content: center; align-items: center;"
                          >
                            <div
                              :style="{ 'background-color': item.color }"
                              class="mr-3"
                              style="width: 25px; height: 25px; "
                            ></div>
                            <span style="font-size: 14px; width: 28px">{{ item.min }}</span>
                            <span v-if="item.max" style="font-size: 14px; ">-</span>
                            <span v-if="!item.max" style="font-size: 14px; ">+</span>
                            <span class="ml-2 mr-2" style="font-size: 14px; width: 30px">{{ item.max }}</span>
                            <span style="font-size: 14px; width: fit-content">{{ item.name }}</span>
                            <v-spacer />
                            <v-spacer />
                          </v-layout>
                        </template>
                        <span style="white-space: pre-wrap; display: block; max-width: 200px;">{{
                          item.description
                        }}</span>
                      </v-tooltip>
                    </v-layout>
                  </v-layout>
                  <Map
                    ref="map"
                    :isLoading="loading"
                    @showHistorical="feature => getHistoricalData(feature)"
                    @showDashBoard="(feature, isUpdate) => $refs.dashboard.showDashboard(feature, isUpdate)"
                  ></Map>
                  <TourGuide
                    style="position: absolute; bottom: 40px; right: 125px; z-index: 2"
                    ref="tourGuide"
                    :color="'#e3e3e3'"
                    :iconColor="'#66808d'"
                    v-if="elements"
                    :elements="elements"
                  />
                </div>
              </v-card>
              <v-card
                :style="{
                  height: currentStation && showable ? 'fit-content' : '5%',
                  'min-height': currentStation && showable ? 'fit-content' : '5%',
                }"
                class="custom-card-bg-0 mt-4  "
                width="100%"
                :class="{ 'mb-5': !showable }"
              >
                <div
                  ref="chart"
                  class=" "
                  style="flex: none; width: 100%; min-height: 50px;border: 1px solid #2F3241; border-radius: 8px;position:relative;overflow: hidden;"
                >
                  <div>
                    <div style="flex: none; width: 100%; height: fit-content; min-height: 0">
                      <v-layout align-center class="fill-height">
                        <v-tabs
                          v-model="tab"
                          fixed-tabs
                          height="50"
                          style="max-height: 50px; border-top-left-radius: 8px; border-top-right-radius: 8px"
                        >
                          <v-tabs-slider color="primary"></v-tabs-slider>
                          <v-tab :disabled="!currentStation" class="ml-1 custom-tab-name">
                            Air Quality Index Historical
                          </v-tab>
                          <v-tab :disabled="!currentStation" class="ml-0 custom-tab-name">
                            Daily air quality distribution
                          </v-tab>
                          <v-spacer />
                          <v-divider vertical />
                          <v-btn
                            :disabled="!currentStation"
                            icon
                            small
                            class="mr-2 mt-2 ml-2"
                            @click="showable = !showable"
                          >
                            <v-icon>{{ showable ? 'icon-chevrons_down' : 'icon-chevrons_up' }}</v-icon>
                          </v-btn>
                        </v-tabs>
                      </v-layout>
                    </div>
                  </div>

                  <v-overlay :value="chartLoading" absolute>
                    <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
                  </v-overlay>
                  <v-divider />
                  <v-layout class="ml-2 mt-2">
                    <template v-if="currentStation && showable">
                      <div
                        v-for="i in historical.data && historical.data[0] && historical.data[0].components
                          ? Object.keys(historical.data[0].components)
                          : []"
                        :key="i"
                      >
                        <v-card
                          style="cursor: pointer;"
                          :class="{ 'custom-card-bg-2': historical.index == i }"
                          class="custom-card-bg-0 custom-btn "
                          @click="historical.index = i"
                        >
                          <span v-html="getIndexName(i).name"></span>
                        </v-card>
                      </div>
                    </template>

                    <v-spacer></v-spacer>
                    <h4 v-if="tab == 1 && showable" class="mr-2">{{ historical.data.length }} days in totals</h4>
                  </v-layout>
                  <v-tabs-items
                    v-model="tab"
                    class="custom-menu-tab"
                    style="height: calc(100% - 50px); background-color: transparent"
                  >
                    <v-tab-item style="height: 100%;" class="tab1">
                      <div v-if="currentStation && showable" class="ml-2 mb-5" style="height: 100%;">
                        <CalendarChart :dataList="historical"></CalendarChart>
                      </div>
                    </v-tab-item>
                    <v-tab-item :style="{ height: tabHeight }"
                      ><distribution
                        v-if="currentStation && showable"
                        :dataList="historical"
                        :tabHeight="tabHeight"
                      ></distribution
                    ></v-tab-item>
                  </v-tabs-items>
                </div>
              </v-card>
            </v-layout>
          </v-col>
        </v-row>
      </v-layout>
    </v-layout>
  </div>
</template>

<script>
import BasicCreateForm from '@/components/BasicCreateForm.vue'
import Map from '@/components/Map'
import Dashboard from './Dashboard.vue'
import LayerControl from '@/components/layer-control/LayerControl.vue'
import { mapState } from '@/store/ults'
import CreateOrder from '@/views/createOrder/CreateOrder.vue'
import { getHistorical } from '@/api/aqi-api'
import CalendarChart from '@/components/CalendarHeatmapChart.vue'
import { getStations } from '@/api/aqi-api'
import Distribution from './Distribution.vue'
import TourGuide from '@/components/GuideTour/index.vue'
export default {
  name: 'aqi-index',
  components: {
    LayerControl,
    Map,
    BasicCreateForm,
    CreateOrder,
    Dashboard,
    CalendarChart,
    Distribution,
    TourGuide,
  },
  data() {
    return {
      updateInterval: null,
      isDraw: false,
      loading: false,
      chartLoading: false,
      tab: 0,
      layers: [],
      showLegend: true,
      oldLayers: [],
      searchLayers: [],
      showable: false,
      leftMenu: true,
      allData: [],
      layerName: '',
      drawType: '',
      currentStation: undefined,
      displayType: '',
      historical: { data: [], index: '' },
      tabHeight: 'auto',
      elements: undefined,
      legend: [
        {
          name: 'Good',
          min: 0,
          max: 50,
          color: '#009966',
          description: 'Air quality is considered satisfactory, and air pollution poses little or no risk',
        },
        {
          name: 'Moderate',
          min: 50,
          max: 100,
          color: '#ffde33',
          description:
            'Air quality is acceptable; however, for some pollutants there may be a moderate health concern for a very small number of people who are unusually sensitive to air pollution.	Active children and adults, and people with respiratory disease, such as asthma, should limit prolonged outdoor exertion.',
        },
        {
          name: 'Unhealthy for Sensitive Groups',
          min: 100,
          max: 150,
          color: '#ff9933',
          description:
            'Members of sensitive groups may experience health effects. The general public is not likely to be affected.	Active children and adults, and people with respiratory disease, such as asthma, should limit prolonged outdoor exertion.',
        },
        {
          name: 'Unhealthy',
          color: '#cc0033',
          min: 150,
          max: 200,
          description:
            'Everyone may begin to experience health effects; members of sensitive groups may experience more serious health effects	Active children and adults, and people with respiratory disease, such as asthma, should avoid prolonged outdoor exertion; everyone else, especially children, should limit prolonged outdoor exertion',
        },
        {
          name: 'Very Unhealthy',
          color: '#660099',
          min: 200,
          max: 300,
          description:
            'Health warnings of emergency conditions. The entire population is more likely to be affected.	Active children and adults, and people with respiratory disease, such as asthma, should avoid all outdoor exertion; everyone else, especially children, should limit outdoor exertion.',
        },
        {
          name: 'Hazardous',
          color: '#7e0023',
          min: 300,

          description:
            'Health alert: everyone may experience more serious health effects	Everyone should avoid all outdoor exertion',
        },
      ],
    }
  },
  computed: { ...mapState('auth', ['currentUser']) },
  mounted() {
    this.getStation()
    this.resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target.classList.contains('tab1') && this.tab == 0) {
          this.updateTabHeight()
        }
      }
    })
    const tab1 = this.$el.querySelector('.tab1')
    if (tab1) {
      this.resizeObserver.observe(tab1)
    }
    this.elements = [
      {
        ref: this.$refs.mapContainer,
        id: 'map',
        title: 'Getting Started',
        text: 'Select a station to get data',
        position: 'left',
      },
      {
        ref: this.$refs.dashboardContainer,
        id: 'dashboard',
        title: 'Getting Started',
        text: 'Track station data',
        position: 'right',
        
      },
      {
        ref: this.$refs.chart,
        id: 'chart',
        title: 'Getting Started',
        text: 'Track station data',
        position: 'right',
      },
    ]
  },
  beforeDestroy() {
    clearInterval(this.updateInterval)
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  },
  watch: {
    showable(val) {
      if (val && this.tab === 0) {
        this.updateTabHeight()
      }
      this.$refs.map.reSize()
    },
    tab(val) {
      this.$refs.map.reSize()
    },
    leftMenu() {
      this.$refs.map.reSize()
    },
  },
  methods: {
    updateTabHeight() {
      this.$nextTick(() => {
        const tab1 = this.$el.querySelector('.tab1')
        if (tab1) {
          this.tabHeight = `${tab1.clientHeight}px`
        }
      })
    },
    getIndexName(index) {
      switch (index) {
        case 'pm2_5':
          return {
            name: 'PM<sub>2.5</sub>',
            detail:
              'Fine Particulate Matter: These are tiny particles with a diameter of 2.5 micrometers or less (about 30 times smaller than the width of a human hair). PM2.5 can easily penetrate deep into the lungs and cause respiratory problems, heart disease, and even cancer.',
          }
        case 'co':
          return {
            name: 'CO',
            detail:
              "Carbon Monoxide: This colorless, odorless gas is produced by incomplete combustion of fuels. CO reduces the amount of oxygen that reaches the body's tissues, which can lead to headaches, dizziness, and even death at high concentrations.",
          }

        case 'no2':
          return {
            name: 'NO<sub>2</sub>',
            detail:
              'Nitrogen Dioxide: A reddish-brown gas with a pungent, acrid odor. It is released when fuel is burned at high temperatures, and is a major component of smog.',
          }
        case 'o3':
          return {
            name: 'O<sub>3</sub>',
            detail:
              'Ozone: At ground level, ozone is a harmful air pollutant formed by chemical reactions between sunlight and NO2. Ground-level ozone can irritate the lungs and worsen asthma.',
          }
        case 'so2':
          return {
            name: 'SO<sub>2</sub>',
            detail:
              'Sulfur Dioxide: This gas is formed by burning fossil fuels that contain sulfur. SO2 can irritate the lungs and worsen asthma.',
          }
        case 'pm10':
          return {
            name: 'PM<sub>10</sub>',
            detail:
              'Coarse Particulate Matter: These are larger particles with a diameter of 10 micrometers or less (about the width of a human hair). PM10 can irritate the lungs and worsen existing respiratory conditions.',
          }
      }
    },
    async getHistoricalData(feature) {
      if (!this.currentStation) this.showable = true
      this.currentStation = feature
      try {
        this.chartLoading = true
        let now = new Date()
        let lastYear = new Date(now.getFullYear() - 1, 0, 1)
        lastYear.setHours(lastYear.getHours() + 7)
        let timeInterval = 3 * 30 * 24 * 60 * 60 * 1000 // 3 months in milliseconds
        let promises = []
        for (let time = lastYear; time < now; time = new Date(time.getTime() + timeInterval + 86400000)) {
          let endTime = new Date(time.getTime() + timeInterval)
          if (endTime > now) endTime = now
          promises.push(
            getHistorical({
              lat: feature.geometry.coordinates[1],
              lon: feature.geometry.coordinates[0],
              from_date: time.toISOString(),
              to_date: endTime.toISOString(),
            }),
          )
        }
        let results = await Promise.all(promises)
        this.historical = { data: [].concat(...results.map(res => res.data)), index: 'pm2_5' }
      } catch (error) {
      } finally {
        this.chartLoading = false
      }
    },
    addPointToMap(geoJson) {
      this.$refs.map.displayMarker(geoJson, true)
    },
    async getStation() {
      try {
        this.loading = true
        const results = await Promise.allSettled([getStations({ source: 1 }), getStations({ source: 2 })])
        const successfulResults = results.filter(result => result.status === 'fulfilled').map(result => result.value)
        if (successfulResults.length > 0) {
          const combinedData = successfulResults.reduce((acc, current) => [...acc, ...current.data], [])
          let data = {
            type: 'FeatureCollection',
            features: combinedData.map(item => {
              return {
                properties: {
                  aqi: Number(item.aqi),
                  name: item.name,
                  time: item.time,
                },
                geometry: {
                  type: 'Point',
                  coordinates: [item.lon, item.lat],
                },
                type: 'Feature',
              }
            }),
          }
          this.addPointToMap(data)
          this.updateInterval = setInterval(() => {
            this.updateStation()
          }, 60000)
        } else {
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async updateStation() {
      try {
        const results = await Promise.allSettled([getStations({ source: 1 }), getStations({ source: 2 })])
        const successfulResults = results.filter(result => result.status === 'fulfilled').map(result => result.value)
        if (successfulResults.length > 0) {
          const features = successfulResults.reduce((acc, current) => {
            const currentFeatures = current.data.map(item => ({
              properties: {
                aqi: Number(item.aqi),
                name: item.name,
                time: item.time,
              },
              geometry: {
                type: 'Point',
                coordinates: [item.lon, item.lat],
              },
              type: 'Feature',
            }))
            return [...acc, ...currentFeatures]
          }, [])

          let data = {
            type: 'FeatureCollection',
            features,
          }
          this.$refs.map.updateSourceData('markers', data)
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style scoped>
.columnChart {
  height: 300px;
}
.custom-btn {
  cursor: pointer;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
}
.legend {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 100;
  padding: 10px;
  border-radius: 8px;
  background: var(--v-bgListItem-base);
}
</style>
