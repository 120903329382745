<template>
  <v-layout style="display: flex; align-items: center; justify-content: center;">
    <v-card style="position: relative;" class="custom-card-bg-0" height="100%" width="97%">
      <v-overlay :value="loading" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card style="background-color: transparent;position: relative;overflow: hidden;" height="100%" width="100%">
        <v-card class="custom-card-bg-2 mb-6" style="position: sticky;top:0;right: 0;">
          <v-layout align-center class="mr-2 ml-2 mt-2">
            <v-card
              v-if="currentStation"
              class="ml-2 custom-card-bg-0"
              width="100%"
              style="font-weight: bold;font-size: 18px;"
              >{{ currentStation.properties.name || 'Station Name' }}</v-card
            >
            <v-spacer></v-spacer>
          </v-layout>
          <v-row>
            <v-layout style="position: relative;">
              <v-col cols="6">
                <div style="display: flex; align-items: center;justify-content: center;">
                  <div :style="{ backgroundColor: getStatus(currentStation.properties.aqi).color }" class="aqi-card">
                    <span v-if="currentStation.properties.aqi !== 'null'">{{ currentStation.properties.aqi }}</span>
                    <span v-else style="font-size: 25px;font-weight: bold;color: white;">-</span>
                  </div>
                </div>
              </v-col>
              <v-col cols="6">
                <v-layout column style="position: relative;height: 100%;"
                  ><div class="status-text" :style="{ color: getStatus(currentStation.properties.aqi).color }">
                    {{ getStatus(currentStation.properties.aqi).status }}
                  </div>
                  <div style="font-size: 16px" :style="{ color: getStatus(currentStation.properties.aqi).color }">
                    {{ getStatus(currentStation.properties.aqi).detail }}
                  </div>
                  <div v-if="currentStation.properties.time" style="font-size: 10px;">Update {{ timeAgo }}</div>
                  <v-layout class="location">
                    <v-icon size="18">mdi-map-marker</v-icon>
                    <div style="font-size: 12px;">
                      {{
                        currentStation.geometry
                          ? currentStation.geometry.coordinates.map(coord => parseFloat(coord.toFixed(3)))
                          : [0, 0]
                      }}
                    </div>
                  </v-layout>
                </v-layout>
              </v-col>
            </v-layout>
          </v-row></v-card
        >
        <h4>Forecast Data</h4>
        <div
          v-for="(chart, index) in Object.keys(charts)"
          :key="index"
          class="mt-2 pb-5 "
          style="flex: none; width: 100%; min-height: 50px;height:102px; border: 1px solid #2F3241; border-radius: 8px;"
        >
          <v-layout align-center class="mr-2 ml-2 mt-2">
            <h4 style="width:50px;cursor: pointer;" v-html="getIndexName(chart).name"></h4>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" size="17" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span style="white-space: pre-wrap; display: block; max-width: 200px;">{{
                getIndexName(chart).detail
              }}</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <div v-if="data.statistics[chart]" style="width: 100px;" class="ml-10">
              {{ `Max: ${data.statistics[chart].max}` }}
            </div>
            <div v-if="data.statistics[chart]" style="width: 100px;" class="ml-5">
              {{ `Min: ${data.statistics[chart].min}` }}
            </div>
          </v-layout>
          <canvas :id="`${chart}`" style="height: 104px; width: 100%; flex: none; max-width: 100%"></canvas>
        </div>
      </v-card>
    </v-card>
  </v-layout>
</template>

<script>
import { getForecast, getHistorical } from '@/api/aqi-api'
import moment from 'moment'
import Chart from 'chart.js/auto'
import { transparentize } from '@/utils/utils'
export default {
  name: 'Dashboard',
  data() {
    return {
      data: { data: [], statistics: {} },
      currentStation: { properties: {} },
      loading: false,
      historicalData: [],
      charts: {
        pm2_5: undefined,
        pm10: undefined,
        o3: undefined,
        no2: undefined,
        so2: undefined,
        co: undefined,
      },
      currentTime: new Date(),
      chartData: [],
      labels: [],
    }
  },
  computed: {
    timeAgo() {
      let time = new Date(this.currentStation.properties.time)
      return moment(time).from(this.currentTime)
    },
  },
  mounted() {
    this.filterData()
    this.interval = setInterval(() => {
      this.currentTime = new Date()
    }, 60000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  watch: {},
  methods: {
    getIndexName(index) {
      switch (index) {
        case 'pm2_5':
          return {
            name: 'PM<sub>2.5</sub>',
            detail:
              'PM2.5 (Fine Particulate Matter): These are tiny particles with a diameter of 2.5 micrometers or less (about 30 times smaller than the width of a human hair). PM2.5 can easily penetrate deep into the lungs and cause respiratory problems, heart disease, and even cancer.',
          }
        case 'co':
          return {
            name: 'CO',
            detail:
              "CO (Carbon Monoxide): This colorless, odorless gas is produced by incomplete combustion of fuels. CO reduces the amount of oxygen that reaches the body's tissues, which can lead to headaches, dizziness, and even death at high concentrations.",
          }

        case 'no2':
          return {
            name: 'NO<sub>2</sub>',
            detail:
              'NO2 (Nitrogen Dioxide): This gas is formed by high-temperature combustion processes like vehicle emissions and power plants. NO2 can irritate the lungs and contribute to smog formation.',
          }
        case 'o3':
          return {
            name: 'O<sub>3</sub>',
            detail:
              'O3 (Ozone): At ground level, ozone is a harmful air pollutant formed by chemical reactions between sunlight and NO2. Ground-level ozone can irritate the lungs and worsen asthma.',
          }
        case 'so2':
          return {
            name: 'SO<sub>2</sub>',
            detail:
              'SO2 (Sulfur Dioxide): This gas is formed by burning fossil fuels that contain sulfur. SO2 can irritate the lungs and worsen asthma.',
          }
        case 'pm10':
          return {
            name: 'PM<sub>10</sub>',
            detail:
              'PM10 (Coarse Particulate Matter): These are larger particles with a diameter of 10 micrometers or less (about the width of a human hair). PM10 can irritate the lungs and worsen existing respiratory conditions.',
          }
      }
    },
    async showDashboard(feature, isUpdate) {
      if (
        feature.properties.name == this.currentStation.name &&
        feature.properties.time == this.currentStation.properties.time
      )
        return
      try {
        this.loading = !isUpdate
        this.currentStation = feature
        let res = await getForecast({ lat: feature.geometry.coordinates[1], lon: feature.geometry.coordinates[0] })
        this.data = {
          data: res.data.data.map(item => {
            Object.keys(item.components).map(key => {
              item.components[key] = Number(item.components[key])
            })
            item.components.datetime = item.dt

            return item.components
          }),
          statistics: res.data.statistics,
        }

        this.filterData()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    getStatus(aqi) {
      if (aqi < 50) {
        return {
          color: '#009966',
          status: 'Good',
        }
      } else if (aqi < 100) {
        return {
          color: '#ffde33',
          status: 'Moderate',
        }
      } else if (aqi < 150) {
        return {
          color: '#ff9933',
          status: 'Unhealthy',
          detail: 'for Sensitive Groups',
        }
      } else if (aqi < 200) {
        return {
          color: '#cc0033',
          status: 'Unhealthy',
        }
      } else if (aqi < 300) {
        return {
          color: '#660099',
          status: 'Very Unhealthy',
        }
      } else if (aqi >= 300) {
        return {
          color: '#7e0023',
          status: 'Hazardous',
        }
      } else {
        return {
          color: '#808080',
          status: 'No data',
        }
      }
    },
    filterData() {
      for (let key in this.charts) {
        if (this.charts[key]) {
          this.updateChart(this.data.data, key, [
            { index: key, color: '#116DF2', type: 'bar', label: key, yAxisID: 'y' },
          ])
        } else {
          this.createChart(this.data.data, key, [
            { index: key, color: '#116DF2', type: 'bar', label: key, yAxisID: 'y' },
          ])
        }
      }
    },
    updateChart(data, id, indies) {
      this.charts[id].data.labels = data.map(val => val.datetime)
      let datasets = []
      indies.forEach(item => {
        if (item.type === 'line') {
          datasets.unshift({
            type: 'line',
            label: item.label ? item.label : item.index[0].toUpperCase() + item.index.substring(1),
            data: data.map(val => val[item.index]),
            fill: item.fill,
            tension: item.tension,
            pointRadius: item.radius,
            pointHoverRadius: 4,
            backgroundColor: data.map(val => {
              const value = val[item.index]
              return this.getStatus(value).color
            }),
            borderColor: item.color,
            yAxisID: item.yAxisID ? item.yAxisID : 'y',
          })
        } else if (item.type === 'bar') {
          datasets.unshift({
            type: 'bar',
            label: item.label ? item.label : item.index[0].toUpperCase() + item.index.substring(1),
            data: data.map(val => val[item.index]),
            fill: item.color,
            backgroundColor: data.map(val => {
              const value = val[item.index]
              return this.getStatus(value).color
            }),
            borderColor: item.color,
            yAxisID: item.yAxisID ? item.yAxisID : 'y',
          })
        }
      })
      this.charts[id].data.datasets = datasets
      this.charts[id].update()
      this.charts[id].resize()
    },
    createChart(data, id, indies) {
      this.transformData(data, indies)
      this.displayChart(id)
    },
    transformData(data, indies) {
      this.labels = data.map(val => val.datetime)
      this.chartData = []
      indies.forEach(item => {
        if (item.type === 'line') {
          this.chartData.unshift({
            type: 'line',
            label: item.label ? item.label : item.index[0].toUpperCase() + item.index.substring(1),
            data: data.map(val => val[item.index]),
            fill: item.fill,
            tension: item.tension,
            pointRadius: item.radius,
            pointHoverRadius: 4,
            backgroundColor: transparentize(item.color, 0.5),
            borderColor: item.color,
            yAxisID: item.yAxisID ? item.yAxisID : 'y',
          })
        } else if ((item.type = 'bar')) {
          this.chartData.unshift({
            type: 'bar',
            label: item.label ? item.label : item.index[0].toUpperCase() + item.index.substring(1),
            data: data.map(val => val[item.index]),
            fill: item.color,
            backgroundColor: data.map(val => {
              const value = val[item.index]
              return this.getStatus(value).color
            }),
            borderColor: item.color,
            yAxisID: item.yAxisID ? item.yAxisID : 'y',
          })
        }
      })
    },
    displayChart(id) {
      let ctx = document.getElementById(id)
      let data = {
        labels: this.labels,
        datasets: this.chartData,
      }
      this.charts[id] = new Chart(ctx, {
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            intersect: false,
            mode: 'index',
          },
          plugins: {
            legend: {
              display: false,
              position: window.innerHeight > 350 ? 'right' : 'top',
            },
          },
          scales: {
            x: {
              display: false,
              stacked: true,
              position: 'top',
              grid: {
                color: '#2F3241',
                display: false,
              },
              ticks: {
                color: 'white',
                font: {
                  size: 9,
                },
              },
            },
            y: {
              type: 'linear',
              position: 'left',
              offset: true,
              offsetAmount: 50,
              title: {
                color: this.chartData[0].backgroundColor,
                text: this.chartData[0].label,
                display: true,
              },
              stacked: false,
              grid: {
                color: '#2F3241',
                display: true,
              },
              ticks: {
                color: 'white',
                font: {
                  size: 11,
                },
              },
            },
            y1: {
              type: 'linear',
              position: 'right',
              offset: true,
              offsetAmount: 50,
              title: {
                color: this.chartData[0] ? this.chartData[0].backgroundColor : '',
                text: this.chartData[0] ? this.chartData[0].label : '',
                display: this.chartData.length >= 2,
              },
              stacked: false,
              grid: {
                color: '#2F3241',
                display: false,
              },
              ticks: {
                display: this.chartData.length >= 2,
                color: this.chartData[0] ? this.chartData[0].backgroundColor : '',
                font: {
                  size: 11,
                },
              },
            },
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel
              },
            },
          },
        },
      })
    },
  },
}
</script>

<style scoped>
.aqi-card {
  color: black;

  border-radius: 5px;
  font-size: 80px;
  width: 90%;
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status-text {
  font-size: 20px;
  font-weight: bold;
}
.location {
  position: absolute;
  left: -5px;
  bottom: 0;
}
</style>
