<template>
  <v-layout style="display: flex; align-items: center; justify-content: center;">
    <v-card style="position: relative;" class="custom-card-bg-0" width="100%">
      <v-card style="background-color: transparent;position: relative;overflow: hidden;" width="100%">
        <div
          class="mt-4 pa-3"
          :style="{ 'min-height': tabHeight }"
          style="flex: none; width: 100%; min-height: 200px; border-radius: 8px"
        >
          <canvas
            id="distribution"
            style="height: 100%; width: 100%; flex: none; max-height: 100%; max-width: 99%"
          ></canvas>
        </div>
      </v-card>
    </v-card>
  </v-layout>
</template>

<script>
import moment from 'moment'
import Chart from 'chart.js/auto'
import { transparentize } from '@/utils/utils'
export default {
  name: 'Distribution',
  data() {
    return {
      currentStation: { properties: {} },
      loading: false,
      historicalData: [],
      distribution: undefined,
      chartData: [],
      labels: ['0-50', '50-100', '100-150', '150-200', '200-300', '300+'],
    }
  },
  props: {
    dataList: {
      type: Object,
      required: true,
    },
    tabHeight: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.filterData()
  },
  computed: {
    data() {
      let componentRanges = {}
      this.dataList.data.forEach(item => {
        Object.keys(item.components).forEach(key => {
          if (!componentRanges[key]) {
            componentRanges[key] = {
              '0-50': 0,
              '50-100': 0,
              '100-150': 0,
              '150-200': 0,
              '200-300': 0,
              '300+': 0,
            }
          }
          let value = Number(item.components[key])
          if (value <= 50) componentRanges[key]['0-50']++
          else if (value <= 100) componentRanges[key]['50-100']++
          else if (value <= 150) componentRanges[key]['100-150']++
          else if (value <= 200) componentRanges[key]['150-200']++
          else if (value <= 300) componentRanges[key]['200-300']++
          else componentRanges[key]['300+']++
        })
      })

      let totalCount = this.dataList.data.length
      let percentages = {}
      Object.keys(componentRanges).forEach(component => {
        percentages[component] = {}
        Object.keys(componentRanges[component]).forEach(range => {
          percentages[component][range] = {}
          percentages[component][range]['percent'] = (componentRanges[component][range] / totalCount) * 100
          percentages[component][range]['count'] = componentRanges[component][range]
        })
      })
      return {
        data: percentages,
      }
    },
  },
  watch: {
    'dataList.index': function(val) {
      this.filterData()
    },
    'dataList.data': function(val) {
      this.filterData()
    },
  },
  methods: {
    getIndexName(index) {
      switch (index) {
        case 'pm2_5':
          return 'PM<sub>2.5</sub>'
        case 'co':
          return 'CO'
        case 'no':
          return 'NO'
        case 'no2':
          return 'NO<sub>2</sub>'
        case 'o3':
          return 'O<sub>3</sub>'
        case 'so2':
          return 'SO<sub>2</sub>'
        case 'pm10':
          return 'PM<sub>10</sub>'
        case 'nh3':
          return 'NH<sub>3</sub>'
      }
    },
    getLabelColor(label) {
      switch (label) {
        case '0-50':
          return '#009966'
        case '50-100':
          return '#ffde33'
        case '100-150':
          return '#ff9933'
        case '150-200':
          return '#cc0033'
        case '200-300':
          return '#660099'
        case '300+':
          return '#7e0023'
      }
    },
    getTimeAgo(time) {
      return moment(time).fromNow()
    },
    getStatus(aqi) {
      if (aqi < 50) {
        return {
          color: '#009966',
          status: 'Good',
        }
      } else if (aqi < 50) {
        return {
          color: '#059a65',
          status: 'Moderate',
        }
      } else if (aqi < 100) {
        return {
          color: '#ffde33',
          status: 'Unhealthy',
          detail: 'for Sensitive Groups',
        }
      } else if (aqi < 150) {
        return {
          color: '#ff9933',
          status: 'Unhealthy',
        }
      } else if (aqi < 200) {
        return {
          color: '#660099',
          status: 'Very Unhealthy',
        }
      } else if (aqi >= 300) {
        return {
          color: '#7e0023',
          status: 'Hazardous',
        }
      } else {
        return {
          color: '#808080',
          status: 'No data',
        }
      }
    },
    filterData() {
      if (this.distribution) {
        this.updateChart(this.data.data, 'distribution', [
          { index: this.dataList.index, color: '#116DF2', type: 'bar', label: this.dataList.index, yAxisID: 'y' },
        ])
      } else {
        this.createChart(this.data.data, 'distribution', [
          { index: this.dataList.index, color: '#116DF2', type: 'bar', label: this.dataList.index, yAxisID: 'y' },
        ])
      }
    },
    updateChart(data, id, indies) {
      let datasets = []
      ;(this[id].data.labels = this.labels.map(label => [
        label,
        `${this.data.data[this.dataList.index][label].count} days`,
      ])),
        indies.forEach(item => {
          if (item.type === 'line') {
            datasets.unshift({
              type: 'line',
              label: item.label ? item.label : item.index[0].toUpperCase() + item.index.substring(1),
              data: data[item.index],
              fill: item.fill,
              tension: item.tension,
              pointRadius: item.radius,
              pointHoverRadius: 4,
              borderColor: item.color,
              yAxisID: item.yAxisID ? item.yAxisID : 'y',
            })
          } else if (item.type === 'bar') {
            datasets.unshift({
              type: 'bar',
              data: Object.keys(data[item.index]).map((key, index) => data[item.index][key].percent),
              label: '%',
              fill: item.color,
              backgroundColor: Object.keys(data[item.index]).map((item, index) =>
                this.getLabelColor(this.labels[index]),
              ),
              borderColor: item.color,
              yAxisID: item.yAxisID ? item.yAxisID : 'y',
            })
          }
        })
      this[id].data.datasets = datasets
      this[id].update()
      this[id].resize()
    },

    createChart(data, id, indies) {
      this.transformData(data, indies)
      this.displayChart(id)
    },
    transformData(data, indies) {
      this.chartData = []
      indies.forEach(item => {
        if (item.type === 'line') {
          this.chartData.unshift({
            type: 'line',
            label: item.label ? item.label : item.index[0].toUpperCase() + item.index.substring(1),
            data: data[item.index],
            fill: item.fill,
            tension: item.tension,
            pointRadius: item.radius,
            pointHoverRadius: 4,
            backgroundColor: transparentize(item.color, 0.5),
            borderColor: item.color,
            yAxisID: item.yAxisID ? item.yAxisID : 'y',
          })
        } else if ((item.type = 'bar')) {
          this.chartData.unshift({
            type: 'bar',
            label: '%',
            data: Object.keys(data[item.index]).map((key, index) => data[item.index][key].percent),
            fill: item.color,
            borderColor: item.color,
            backgroundColor: Object.keys(data[item.index]).map((item, index) => this.getLabelColor(this.labels[index])),
            yAxisID: item.yAxisID ? item.yAxisID : 'y',
          })
        }
      })
    },
    displayChart(id) {
      let ctx = document.getElementById(id)
      let data = {
        labels: this.labels.map(label => [label, `${this.data.data[this.dataList.index][label].count} days`]),
        datasets: this.chartData,
      }
      this[id] = new Chart(ctx, {
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            intersect: false,
            mode: 'index',
          },
          plugins: {
            legend: {
              display: false,
              position: window.innerHeight > 350 ? 'right' : 'top',
            },
          },
          scales: {
            x: {
              display: true,
              stacked: true,
              grid: {
                color: '#2F3241',
                display: false,
              },
              ticks: {
                color: 'white',
                font: {
                  size: 12,
                },
              },
            },
            y: {
              type: 'linear',
              position: 'left',
              offset: true,
              offsetAmount: 50,
              title: {
                color: this.chartData[0].backgroundColor,
                text: this.chartData[0].label,
                display: true,
              },
              stacked: false,
              grid: {
                color: '#2F3241',
                display: true,
              },
              ticks: {
                color: 'white',
                font: {
                  size: 11,
                },
              },
            },
            y1: {
              type: 'linear',
              position: 'right',
              offset: true,
              offsetAmount: 50,
              title: {
                color: this.chartData[0] ? this.chartData[0].backgroundColor : '',
                text: this.chartData[0] ? this.chartData[0].label : '',
                display: this.chartData.length >= 2,
              },
              stacked: false,
              grid: {
                color: '#2F3241',
                display: false,
              },
              ticks: {
                display: this.chartData.length >= 2,
                color: this.chartData[0] ? this.chartData[0].backgroundColor : '',
                font: {
                  size: 11,
                },
              },
            },
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel
              },
            },
          },
        },
      })
    },
  },
}
</script>

<style scoped></style>
